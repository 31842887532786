body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.custom-page {
  padding-top: 5rem;
}

.custom-sidebar-container {
  padding-top: 32px;
}

.custom-top-boxshadow {
  -webkit-box-shadow: 5px 5px 4px -6px rgb(0 0 0);
  -moz-box-shadow: 5px 5px 4px -6px rgb(0 0 0);
  box-shadow: 5px 5px 4px -6px rgb(0 0 0);
}

.custom-card-boxshadow {
  -webkit-box-shadow: 5px 5px 4px -6px rgb(0 0 0);
  -moz-box-shadow: 5px 5px 4px -6px rgb(0 0 0);
  box-shadow: 5px 5px 4px -6px rgb(0 0 0);
}

.grid-container {
  width: 200px;
  height: 72px;
  box-shadow: 4px 6px 6px -7px rgb(0 0 0);
}

.custom-bg-green {
  background-color: #f4f7df !important;
  border: #f4f7df !important;;
}
.custom-bg-red {
  background-color: #fff6f6 !important;
}
