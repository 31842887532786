.sidebar {
  position: relative;
  min-height: 100% !important;
  min-width: 100% !important;
  z-index: 100;
  padding-top: 42px;
  padding-right: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  font-size: 0.765625rem;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #30363b;
  color: rgba(255, 255, 255, 0.5);
}

#sideNav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

#sideNav .nav-link:hover {
  color: #fff;
}

#sideNav .nav-link.active {
  color: #fff;
}

#profileNav .nav-item {
  margin-right: 0 !important;
}

#profileNav a:hover {
  color: #0d6efd;
}

#profileNav .nav-link.active {
  color: rgba(8, 0, 255, 0.895);
}

.billingSl {
  width: 100px;
}

.billingAm {
  width: 250px;
}

.billingAc {
  width: 150px;
}

.testSpan {
  column-count: 2;
}

@media only screen and (max-width: 768px) {
  .billingSl {
    width: 50px;
    display: none;
  }
  
  .billingAm {
    width: 100px;
    display: none;
  }

  .billingAc {
    width: 50px;
  }

  .testSpan {
    column-count: 1;
  }
}